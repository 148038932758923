<template>
  <div class="account">
    <div class="header">
      <!-- <div class="title">
                <el-button type="primary" icon="el-icon-plus">添加</el-button>
                <el-button icon="el-icon-refresh">刷新</el-button>
            </div> -->
      <div class="task">
        <!-- <div>
                    <el-select v-model="typeInfo" placeholder="识别类型" @change="searchClick">
                        <el-option
                            v-for="item in typeInfoOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>    
                    </el-select>
                </div>
                <div>
                    <el-select
                        clearable
                        v-model="statusValue"
                        placeholder="任务状态"
                        @change="searchClick"
                    >
                        <el-option
                            v-for="item in taskTypeStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div> -->
        <div>
          <el-input
            v-model="Keyword"
            clearable
            placeholder="任务名称"
          ></el-input>
        </div>
        <div>
          <el-input
            v-model="SourceID"
            clearable
            placeholder="编号SourceID"
          ></el-input>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-search" @click="searchClick"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table
        :data="list"
        border
        height="calc(100vh - 400px)"
        :header-cell-style="{ background: '#FAFAFA' }"
      >
        <el-table-column prop="taskName" label="任务名称" min-width="30">
          <template slot-scope="scope">
            <div
              :title="scope.row.taskName"
              style="overflow: hidden; white-space: nowrap"
            >
              {{ scope.row.taskName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sourceID"
          label="加工编号"
          min-width="70"
        ></el-table-column>
        <el-table-column
          prop="imageCount"
          label="图片总数"
          min-width="30"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="送入时间"
          min-width="60"
        ></el-table-column>
        <el-table-column label="识别完成时间" min-width="60">
          <template slot-scope="scope">
            <div v-if="scope.row.successTime != '0001-01-01 08:00:00'">
              {{ scope.row.successTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="consumeTime"
          label="耗时"
          min-width="60"
        ></el-table-column>
        <el-table-column label="状态" min-width="30">
          <template slot-scope="scope">
            <div class="">
              <div class="" v-if="scope.row.taskType == 1">等待识别</div>
              <div class="" v-if="scope.row.taskType == 2">识别中</div>
              <div class="" v-if="scope.row.taskType == 3">识别错误</div>
              <div class="" v-if="scope.row.taskType == 4">识别完成</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="排版" min-width="30">
          <template slot-scope="scope">
            <div class="">
              {{ scope.row.isVertical ? "纵向" : "非纵向" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="40">
          <template slot-scope="scope">
            <el-button
              plain
              type="primary"
              size="small"
              style="margin-left: 1px"
              @click="Detail(scope.row.taskInfoDetaiDtos)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="PageIndex"
          :page-sizes="[9, 25, 50, 75, 100]"
          :page-size="PageSize"
          prev-text="上一页"
          next-text="下一页"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="Totals"
        >
        </el-pagination>
      </div>
    </div>
    <div class="">
      <el-dialog
        title="任务详情"
        :visible.sync="dialogTableVisible"
        :close-on-click-modal="false"
        width="960px"
        top="10vh"
        :before-close="handleClose"
      >
        <div class="">
          <div style="display: flex">
            <el-input
              style="width: 300px"
              v-model="imgKeyword"
              clearable
              placeholder="图片文件名称"
              @keydown.enter.native="imgSearch"
            ></el-input>
            <el-select
              clearable
              v-model="taskTypeValue"
              placeholder="状态"
              @change="imgSearch"
            >
              <el-option
                v-for="item in taskTypeStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-search"
              @click="imgSearch"
              >搜索</el-button
            >
          </div>
          <el-table height="60vh" border :data="imgList">
            <el-table-column type="index"></el-table-column>
            <el-table-column
              property="pictureFileName"
              label="图片文件名称"
            ></el-table-column>
            <el-table-column
              property="picturePath"
              label="图片文件路径"
              min-width="110"
            ></el-table-column>
            <el-table-column label="状态" min-width="30">
              <template slot-scope="scope">
                <div class="">
                  <div class="" v-if="scope.row.taskType == 0">未开始</div>
                  <div class="" v-if="scope.row.taskType == 1">等待识别</div>
                  <div class="" v-if="scope.row.taskType == 2">识别中</div>
                  <div class="" v-if="scope.row.taskType == 3">识别错误</div>
                  <div class="" v-if="scope.row.taskType == 4">识别完成</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 页码 -->
          <div class="paging">
            <el-pagination
              @size-change="imgHandleSizeChange"
              @current-change="imgHandleCurrentChange"
              :current-page="imgPageIndex"
              :page-sizes="[9, 25, 50, 75, 100]"
              :page-size="imgPageSize"
              prev-text="上一页"
              next-text="下一页"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="imgTotals"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      list: [],
      imgAllList: [],
      detailData: [],
      imgList: [],
      imgKeyword: "",
      //   弹出层
      dialogTableVisible: false,
      statusValue: "", //识别状态
      typeInfo: "-1", //-1 全部 0普通识别 1二次识别
      taskTypeValue: null, //类型
      Keyword: "", //任务编号
      SourceID: "", //著录资源的加工编号
      value: "", //人员
      Timer: null, //定时器
      /* 分页 */
      PageIndex: 1,
      PageSize: 9,
      Totals: 0,
      /* 图片分页 */
      imgPageIndex: 1,
      imgPageSize: 25,
      imgTotals: 0,
      taskTypeStatusOptions: [
        { value: 4, label: "识别完成" },
        { value: 3, label: "识别错误" },
        { value: 1, label: "等待识别" },
        { value: 2, label: "识别中" },
      ],
    };
  },
  computed: {
    ...mapState([
      "taskTypeOption",
      "wordOption",
      "typeInfoOption",
      // "taskTypeStatusOptions",
      "userlist",
    ]),
  },
  created() {
    this.GetList();
    this.Timer = setInterval(() => {
      this.GetList();
    }, 5000);
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.Timer);
  },
  methods: {
    searchClick() {
      this.PageIndex = 1;
      this.GetList();
    },
    //请求列表
    GetList() {
      //this.loading = true;
      if (!this.statusValue) this.statusValue = null;
      this.$request({
        url: "/api/discerntask/user-center",
        method: "GET",
        params: {
          PageSize: this.PageSize,
          PageIndex: this.PageIndex,
          Keyword: this.Keyword,
          SourceID: this.SourceID,
          TypeInfo: this.typeInfo,
          TaskType: this.statusValue,
        },
      })
        .then((res) => {
          this.loading = false;
          res.items.forEach((el) => {
            el.consumeTime = "";
            let timeStamp = new Date(el.successTime) - new Date(el.createTime);
            let days = Math.floor(timeStamp / (24 * 3600 * 1000)); // 计算出天数
            let leavel1 = timeStamp % (24 * 3600 * 1000); // 计算天数后剩余的时间
            let hours = Math.floor(leavel1 / (3600 * 1000)); // 计算天数后剩余的小时数
            let leavel2 = timeStamp % (3600 * 1000); // 计算剩余小时后剩余的分钟数
            let minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数
            let leavel3 = timeStamp % (60 * 1000); // 计算剩余小时后剩余的秒数
            let second = Math.floor(leavel3 / 1000); // 计算剩余的秒数
            if (days > 0) {
              el.consumeTime = `${days}d${hours}h${minutes}m${second}s`;
            } else if (hours > 0) {
              el.consumeTime = `${hours}h${minutes}m${second}s`;
            } else if (minutes > 0) {
              el.consumeTime = `${minutes}m${second}s`;
            } else if (second > 0) {
              el.consumeTime = `${second}s`;
            }
            // console.log(`${days}天${hours}时${minutes}分${second}秒`)
          });
          this.list = res.items;
          this.Totals = res.totalCount;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    //关闭弹出层
    handleClose() {
      this.dialogTableVisible = false;
    },
    //详情弹出层
    Detail(data) {
      this.imgAllList = data;
      this.detailData = data;
      this.imgTotals = data.length;
      this.imgList = data.slice(0, this.imgPageSize);
      this.dialogTableVisible = true;
    },
    //搜索
    imgSearch() {
      var patrn =
        /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;

      //this.imgPageIndex = 1
      var str = "";
      for (let key of this.imgKeyword) {
        patrn.test(key) ? (str += "\\" + key) : (str += key);
      }
      let reg = new RegExp(str);
      var newArr = this.imgAllList.filter((item) => {
        console.log(this.taskTypeValue);
        if (this.taskTypeValue) {
          if (
            reg.test(item.pictureFileName) &&
            this.taskTypeValue == item.taskType
          ) {
            return item;
          } else {
            return false;
          }
        } else {
          if (reg.test(item.pictureFileName)) {
            return item;
          } else {
            return false;
          }
        }
      });
      this.detailData = newArr;
      this.imgTotals = newArr.length;
      this.imgList = this.detailData.slice(0, this.imgPageSize);
    },
    //分页条数
    handleSizeChange(val) {
      this.PageSize = val;
      this.GetList();
    },
    //分页页数
    handleCurrentChange(val) {
      this.PageIndex = val;
      this.GetList();
    },
    //图片分页
    imgHandleSizeChange(val) {
      this.imgPageIndex = 1;
      this.imgPageSize = val;
      this.imgList = this.detailData.slice(0, this.imgPageSize);
    },
    imgHandleCurrentChange(val) {
      this.imgPageIndex = val;
      val > 1
        ? (this.imgList = this.detailData.slice(
            this.imgPageSize * (this.imgPageIndex - 1),
            this.imgPageSize * this.imgPageIndex
          ))
        : (this.imgList = this.detailData.slice(0, this.imgPageSize));
    },
  },
};
</script>

<style lang="less" scoped>
.account {
  box-sizing: border-box;
}

.header {
  background-color: #fff;

  .title {
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid #eee;
  }

  .task {
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid #eee;
  }
}

.table {
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .el-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .edit,
  .role {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #009688;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    opacity: 1;
    transition: all 0.3s;
  }

  .appcode {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #1e9fff;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    opacity: 1;
    transition: all 0.3s;
  }

  .delete {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #ff5722;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    opacity: 1;
    transition: all 0.3s;
  }

  .cancel {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #ffb800;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    opacity: 1;
    transition: all 0.3s;
  }

  .edit:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }

  .role:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }

  .delete:hover {
    opacity: 0.7;
    transition: all 0.3s;
  }
}

@media screen and(max-width:1300px) {
  /deep/ .el-dialog {
    margin-top: 30px !important;

    .el-dialog__title {
      font-size: 14px;
    }
  }

  /deep/ .el-dialog__body {
    padding-top: 10px;
  }

  /deep/ .el-input {
    width: 100px;
    font-size: 12px;

    .el-input__inner {
      height: 30px !important;
      line-height: 30px !important;
    }
  }

  .el-button {
    padding: 6px 6px;
  }

  .el-table {
    font-size: 12px;
  }

  /deep/ .el-pagination {
    font-size: 12px;
  }
}
</style>
